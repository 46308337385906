<template>
  <div class="employess-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="tableRules"
      @reload="reloadData"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <employee-modal :show.sync="modal.show" :title="modal.title" action="edit" />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters } from 'vuex';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import EmployeeModal from '../employee-modal/employee-modal.vue';
import {
  SELECT_EMPLOYEE,
  DELETE_EMPLOYEE,
  FETCH_EMPLOYEES,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'EmployeeTable',
  components: { AppTable, EmployeeModal },
  mixins: [notifyMixin],
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 60 },
        { label: 'ФИО', value: 'name' },
        { label: 'Email', value: 'email' },
        { label: 'Филиал', value: 'branch.title' },
        { label: 'Роль', value: 'roles[0].name', width: 110 },
      ],
      tableRules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      modal: {
        show: false,
        title: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
  },
  methods: {
    ...mapActions({ select: SELECT_EMPLOYEE, delete: DELETE_EMPLOYEE, fetch: FETCH_EMPLOYEES }),
    async reloadData(page) {
      await this.fetch({ page });
    },
    async handleEdit(employee) {
      this.select({
        ...employee,
        role_id: employee.roles[0].id,
        branch_id: employee.branch ? employee.branch.id : null,
      });
      this.modal.show = true;
      this.modal.title = 'Редактирование сотрудника';
    },
    handleDelete(employee) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(employee.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Сотрудник успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>
