<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form
      ref="employee"
      label-position="top"
      :model="employee"
      :rules="formRules"
      @submit.prevent.native="saveFn"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="ФИО" prop="name">
            <el-input v-model="employee.name" placeholder="ФИО" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Email" prop="email">
            <el-input v-model="employee.email" placeholder="Email" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Пароль" prop="password">
            <el-input v-model="employee.password" placeholder="Пароль" type="password" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Роль" prop="role_id">
            <el-select v-model="employee.role_id" placeholder="Выберите роль">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="employee.branch" :span="12">
          <el-form-item label="Филиал">
            <el-autocomplete
              v-model="employee.branch.title"
              style="width: 100%"
              :fetch-suggestions="searchBranchesFn"
              :debounce="600"
              placeholder="Выберите филиал"
              value-key="title"
              @select="selectBranchFn"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_BRANCHES } from '@/app/settings/branches/shared/state/types/action_types';
import { DATA as DATA_BRANCHES } from '@/app/settings/branches/shared/state/types/getter_types';
import { LOADING, SELECTED, ERROR } from '../shared/state/types/getter_types';
import {
  STORE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SELECT_EMPLOYEE_RESET,
} from '../shared/state/types/action_types';

import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'EmployeeModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить нового сотрудника',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      formRules: {
        name: [{ required: true, message: 'Укажите фамилиию имя отчество', trigger: 'blur' }],
        email: [{ required: true, type: 'email', message: 'Укажите email', trigger: 'blur' }],
        role_id: [{ required: true, message: 'Выберите роль', trigger: 'blur' }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({ loading: LOADING, employee: SELECTED, error: ERROR, branches: DATA_BRANCHES }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
      isSuperAdmin: (state) => ['super-admin'].includes(state.authState.authState.role),
    }),
    options() {
      return this.isSuperAdmin
        ? [
            { value: 1, label: 'Суперадмин' },
            { value: 2, label: 'Мастер админ' },
            { value: 3, label: 'Главный Администратор' },
            { value: 4, label: 'Администратор' },
            { value: 5, label: 'Менеджер' },
          ]
        : this.isAvailable
        ? [
            { value: 2, label: 'Мастер админ' },
            { value: 3, label: 'Главный Администратор' },
            { value: 4, label: 'Администратор' },
            { value: 5, label: 'Менеджер' },
          ]
        : [
            { value: 3, label: 'Главный Администратор' },
            { value: 4, label: 'Администратор' },
            { value: 5, label: 'Менеджер' },
          ];
    },
  },
  methods: {
    ...mapActions({
      store: STORE_EMPLOYEE,
      update: UPDATE_EMPLOYEE,
      reset: SELECT_EMPLOYEE_RESET,
      fetchBranches: FETCH_BRANCHES,
    }),
    async closeModal() {
      await this.reset();

      this.visible = false;
    },
    async saveFn() {
      switch (this.action) {
        case 'edit':
          await this.update(this.employee);
          if (this.error) {
            this.showErrorMessage('Ошибка обновления');
          }
          this.showSuccessMessage('Успешно обновлен');
          this.closeModal();
          break;
        default:
          await this.store(this.employee);
          if (this.error) {
            this.showErrorMessage('Ошибка добавления');
          }
          this.showSuccessMessage('Успешно добавлен');
          this.closeModal();
          break;
      }
    },
    selectBranchFn(bracnch) {
      this.employee.branch_id = bracnch.id;
    },
    async searchBranchesFn(queryString, cb) {
      await this.fetchBranches({ filter: { title: queryString || '' } });
      cb(this.branches.data);
    },
  },
};
</script>
