<template>
  <div class="app-employee">
    <employee-top-panel @create-employee="openModal" />
    <employee-table />
    <employee-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import EmployeeTable from './employee-table/employee-table.vue';
import EmployeeModal from './employee-modal/employee-modal.vue';
import { FETCH_EMPLOYEES } from './shared/state/types/action_types';
import EmployeeTopPanel from './employee-top-panel/employee-top-panel.vue';

export default {
  name: 'Category',
  components: { EmployeeTable, EmployeeModal, EmployeeTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить сотрудника',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_EMPLOYEES, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>
