<template>
  <div class="employee-top-panel">
    <div class="is-flex is-justify-content-flex-end">
      <div class="add-employee">
        <el-button type="primary" icon="el-icon-plus" @click="openModal">
          Добавить сотрудника
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeTopPanel',
  methods: {
    openModal() {
      this.$emit('create-employee');
    },
  },
};
</script>
